export type AnalyticsEventKey =
  | 'REFRESH_409'
  | 'CONTINUE_409'
  | 'CLOSE_409'
  | 'PageChange'
  | 'AppRecoveredFromKnownError'
  | 'AppKnownError'
  | 'AppErrorCaptured'
  | 'GdprConsent'
  | 'MethodologyOpened'
  | 'EmailVerificationRequested'
  | 'EmailVerificationCompleted'
  | 'ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED'
  | 'MarketingBannerClicked'
  | 'LoginBtnClicked'
  | 'LoginWithPassword'
  | 'LoginSuccess'
  | 'LoginError'
  | 'LoginFailure'
  | 'Logout'
  | 'LogoutFailure'
  | 'PasswordChangeRequested'
  | 'PasswordChangeCompleted'
  | 'PasswordChangeError'
  | 'PasswordResetRequested'
  | 'PasswordResetCompleted'
  | 'PasswordResetError'
  | 'SignupRequested'
  | 'SignupComplete'
  | 'SignupAutoLoginError'
  | 'SignupFailed'
  | 'SignupError'
  | 'PollSubmission'
  | 'PollExpandToggle'
  | 'BasisDealsToggle'
  | 'BasisMorePageItemsClick'
  | 'SettlementCriteriaChange'
  | 'FFASubmissionJustificationChanged'
  | 'FFASubmissionReviewBtnClicked'
  | 'FFASubmissionError'
  | 'FFASubmissionBtnClicked'
  | 'FFASubmissionFinished'
  | 'FFASubmissionGoBack'
  | 'FFASubmissionCancelOnReview'
  | 'FFASubmissionChartPriceTypeChanged'
  | 'FFASubmissionChartToggled'
  | 'FFASubmissionImportPricesModalToggle'
  | 'FFASubmissionImportPricesFromExcelApply'
  | 'SubmissionJustificationChanged'
  | 'SubmissionSegmentToggled'
  | 'SubmissionReviewBtnClicked'
  | 'SubmissionError'
  | 'SubmissionFinished'
  | 'SubmissionBtnClicked'
  | 'SubmissionGoBack'
  | 'SubmissionExitModalToggled'
  | 'SubmissionCancelOnReview'
  | 'SubmissionChartPriceTypeChanged'
  | 'SubmissionChartToggled'
  | 'SubmissionImportPricesModalToggle'
  | 'SubmissionImportPricesFromExcelApply'
  | 'SubmissionRTCalcRatesApplied'
  | 'ExcelDownloadRequest'
  | 'ExcelDownloadComplete'
  | 'ExcelDownloadFailed'
  | 'PriceChartPriceTypeComparisonChanged'
  | 'ReleaseDateChanged'
  | 'ReleaseInfoOpened'
  | 'ActionBarButtonClicked'
  | 'SubmissionStatusRefresh'
  | 'UnitChanged'
  | 'FFAActionBarButtonClicked'
  | 'PriceEvolutionComparisonMonthChanged'
  | 'PriceEvolutionFiltersContractChange'
  | 'PriceEvolutionFiltersContractTypeChange'
  | 'PriceEvolutionFiltersUnitToggled'
  | 'PriceEvolutionFiltersDelMonthChange'
  | 'PriceEvolutionCustomToggled'
  | 'PriceEvolutionFiltersPercentToggled'
  | 'PriceEvolutionDurationChange'
  | 'PriceEvolutionTerminalChange'
  | 'PriceEvolutionSlotTypeChange'
  | 'FFAPriceEvolutionDurationChange'
  | 'FFAPriceEvolutionFiltersContractChange'
  | 'FFAPriceEvolutionFiltersDelMonthChange'
  | 'DiscoverNotesSaved'
  | 'DiscoverNotesLoaded'
  | 'NetbackNotesSaved'
  | 'NetbackNotesLoaded'
  | 'RoutesNotesSaved'
  | 'RoutesNotesLoaded'
  | 'FreightNotesSaved'
  | 'FreightNotesLoaded'
  | 'FFANotesSaved'
  | 'FFANotesLoaded'
  | 'FloatingStorageNotesSaved'
  | 'FloatingStorageNotesLoaded'
  | 'NetForwardsNotesSaved'
  | 'NetForwardsNotesLoaded'
  | 'NetbackArbNotesSaved'
  | 'NetbackArbNotesLoaded'
  | 'FFADiscoverNotesSaved'
  | 'FFADiscoverNotesLoaded'
  | 'FFADiscoverPriceTypeChanged'
  | 'SpreadFiltersChange'
  | 'SpreadFiltersUnitChange'
  | 'RoutesCriteriaUpdated'
  | 'RoutesCriteriaCongestionDaysUpdated'
  | 'NetbacksCriteriaUpdated'
  | 'NetbacksCriteriaCongestionDaysUpdated'
  | 'NetforwardsCriteriaCongestionDaysUpdated'
  | 'FFASpreadFiltersChange'
  | 'SettlementMonthChange'
  | 'SettlementContractChange'
  | 'SettlementContractTypeChange'
  | 'SettlementMaxLossChange'
  | 'SettlementMaxGainChange'
  | 'FreightPriceEvolutionSignalChange'
  | 'FreightPriceEvolutionDurationChange'
  | 'FreightPriceEvolutionCompareFoChange'
  | 'FreightPriceEvolutionCustomRangeChange'
  | 'FreightSpotSignalsToggled'
  | 'RoundtripCalcSubmitBtnClick'
  | 'RoundtripCalcRemoveRate'
  | 'StartTrialRequest'
  | 'TrialPageClosed'
  | 'TrialPageClosureFailed'
  | 'DocumentationLinkClicked'
  | 'LaunchedViaDashboardPage'
  | 'DashboardSignalsContractTypeChange'
  | 'DashboardSignalsContractIdChange'
  | 'DashboardPDContractChange'
  | 'DashboardPDMethodologyOpened'
  | 'DashboardUnitToggle'
  | 'DashboardDerivateToggle'
  | 'DashboardExplorePremium'
  | 'DashboardPDFDownload'
  | 'DashboardDiscussPremium'
  | 'DashboardViewFullDetails'
  | 'BannerClick'
  | 'AccessWindow'
  | 'MapPinTerminal'
  | 'MapUnpinTerminal'
  | 'MapHoverGashub'
  | 'MapTerminalComparison'
  | 'MapHoverTerminal'
  | 'MapUnitChange'
  | 'MapFeedbackModal'
  | 'ComparisonTableCsvModal'
  | 'AnnouncementShown'
  | 'AnnouncementAcknowledged'
  | 'AnnouncementContactUs'
  | 'RecentUpdateClicked'
  | 'NetbacksArbCriteriaUpdated'
  | 'NetbacksArbCriteriaCongestionDaysUpdated'
  | 'PagePromptsLinkClicked'
  | 'GlobalCalculatorLoadDateChanged'
  | 'GlobalCalculatorLoadPortChanged'
  | 'GlobalCalculatorDischargePortChanged'
  | 'GlobalCalculatorViaPointChanged'
  | 'GlobalCalculatorLoadPortDaysChanged'
  | 'GlobalCalculatorDischargePortDaysChanged'
  | 'GlobalCalculatorFlexDaysChanged'
  | 'GlobalCalculatorCanalTransitLadenDaysChanged'
  | 'GlobalCalculatorCanalTransitBallastDaysChanged'
  | 'GlobalCalculatorCongestionLadenDaysChanged'
  | 'GlobalCalculatorCongestionBallastDaysChanged'
  | 'GlobalCalculatorSeaMarginChanged'
  | 'GlobalCalculatorVesselSizeChanged'
  | 'GlobalCalculatorVesselHeelPurchasedChanged'
  | 'GlobalCalculatorVesselCargoLoadedChanged'
  | 'GlobalCalculatorVesselHeelSoldChanged'
  | 'GlobalCalculatorVesselEngineTechChanged'
  | 'GlobalCalculatorEffectiveLadenBoilOffChanged'
  | 'GlobalCalculatorEffectiveBallastBoilOffChanged'
  | 'GlobalCalculatorLadenSpeedChanged'
  | 'GlobalCalculatorLadenMgoChanged'
  | 'GlobalCalculatorLadenFuelOilChanged'
  | 'GlobalCalculatorBallastSpeedChanged'
  | 'GlobalCalculatorBallastMgoChanged'
  | 'GlobalCalculatorBallastFuelOilChanged'
  | 'GlobalCalculatorPortEffectiveBoilOffChanged'
  | 'GlobalCalculatorPortMgoChanged'
  | 'GlobalCalculatorPortFuelOilChanged'
  | 'GlobalCalculatorLngPriceChanged'
  | 'GlobalCalculatorHeelPurchasedChanged'
  | 'GlobalCalculatorHeelSoldChanged'
  | 'GlobalCalculatorMgoPriceChanged'
  | 'GlobalCalculatorFuelOilPriceChanged'
  | 'GlobalCalculatorIncludeCarbonCostChanged'
  | 'GlobalCalculatorIncludeCarbonCostForLoadPortChanged'
  | 'GlobalCalculatorIncludeCarbonCostForLadenTransitChanged'
  | 'GlobalCalculatorIncludeCarbonCostForDischargePortChanged'
  | 'GlobalCalculatorIncludeCarbonCostForBallastTransitChanged'
  | 'GlobalCalculatorIncludeCarbonCostForPositioningTransitChanged'
  | 'GlobalCalculatorCarbonLoadPortVoyageFactorChanged'
  | 'GlobalCalculatorCarbonLoadPortImplementationChanged'
  | 'GlobalCalculatorCarbonLadenTransitVoyageFactorChanged'
  | 'GlobalCalculatorCarbonLadenTransitImplementationChanged'
  | 'GlobalCalculatorCarbonDischargePortVoyageFactorChanged'
  | 'GlobalCalculatorCarbonDischargePortImplementationChanged'
  | 'GlobalCalculatorCarbonBallastTransitVoyageFactorChanged'
  | 'GlobalCalculatorCarbonBallastTransitImplementationChanged'
  | 'GlobalCalculatorCarbonPositioningTransitVoyageFactorChanged'
  | 'GlobalCalculatorCarbonPositioningTransitImplementationChanged'
  | 'GlobalCalculatorIncludeMethaneCostChanged'
  | 'GlobalCalculatorIncludeMethaneCostForLoadPortChanged'
  | 'GlobalCalculatorIncludeMethaneCostForLadenTransitChanged'
  | 'GlobalCalculatorIncludeMethaneCostForDischargePortChanged'
  | 'GlobalCalculatorIncludeMethaneCostForBallastTransitChanged'
  | 'GlobalCalculatorIncludeMethaneCostForPositioningTransitChanged'
  | 'GlobalCalculatorMethaneLoadPortVoyageFactorChanged'
  | 'GlobalCalculatorMethaneLoadPortImplementationChanged'
  | 'GlobalCalculatorMethaneLadenTransitVoyageFactorChanged'
  | 'GlobalCalculatorMethaneLadenTransitImplementationChanged'
  | 'GlobalCalculatorMethaneDischargePortVoyageFactorChanged'
  | 'GlobalCalculatorMethaneDischargePortImplementationChanged'
  | 'GlobalCalculatorMethaneBallastTransitVoyageFactorChanged'
  | 'GlobalCalculatorMethaneBallastTransitImplementationChanged'
  | 'GlobalCalculatorMethanePositioningTransitVoyageFactorChanged'
  | 'GlobalCalculatorMethanePositioningTransitImplementationChanged'
  | 'GlobalCalculatorIncludeN2OCostChanged'
  | 'GlobalCalculatorIncludeN2OCostForLoadPortChanged'
  | 'GlobalCalculatorIncludeN2OCostForLadenTransitChanged'
  | 'GlobalCalculatorIncludeN2OCostForDischargePortChanged'
  | 'GlobalCalculatorIncludeN2OCostForBallastTransitChanged'
  | 'GlobalCalculatorIncludeN2OCostForPositioningTransitChanged'
  | 'GlobalCalculatorN2OLoadPortVoyageFactorChanged'
  | 'GlobalCalculatorN2OLoadPortImplementationChanged'
  | 'GlobalCalculatorN2OLadenTransitVoyageFactorChanged'
  | 'GlobalCalculatorN2OLadenTransitImplementationChanged'
  | 'GlobalCalculatorN2ODischargePortVoyageFactorChanged'
  | 'GlobalCalculatorN2ODischargePortImplementationChanged'
  | 'GlobalCalculatorN2OBallastTransitVoyageFactorChanged'
  | 'GlobalCalculatorN2OBallastTransitImplementationChanged'
  | 'GlobalCalculatorN2OPositioningTransitVoyageFactorChanged'
  | 'GlobalCalculatorN2OPositioningTransitImplementationChanged'
  | 'GlobalCalculatorCarbonPriceChanged'
  | 'GlobalCalculatorHireRateChanged'
  | 'GlobalCalculatorCanalCostChanged'
  | 'GlobalCalculatorBallastBonusFormatChanged'
  | 'GlobalCalculatorBallastBonusToChanged'
  | 'GlobalCalculatorBallastBonusHirePercentageChanged'
  | 'GlobalCalculatorBallastBonusFuelPercentageChanged'
  | 'GlobalCalculatorBallastBonusCanalCostChanged'
  | 'GlobalCalculatorBallastBonusLumpsumChanged'
  | 'GlobalCalculatorPositioningFormatChanged'
  | 'GlobalCalculatorPositioningFromChanged'
  | 'GlobalCalculatorPositioningHirePercentageChanged'
  | 'GlobalCalculatorPositioningFuelPercentageChanged'
  | 'GlobalCalculatorPositioningCanalCostChanged'
  | 'GlobalCalculatorPositioningLumpsumChanged'
  | 'GlobalCalculatorLoadPortCostChanged'
  | 'GlobalCalculatorDischargePortCostChanged'
  | 'GlobalCalculatorInsuranceCostChanged'
  | 'GlobalCalculatorSecurityCostChanged'
  | 'GlobalCalculatorBrokerFeeFormatChanged'
  | 'GlobalCalculatorBrokerFeeChanged'
  | 'GlobalCalculatorOtherExpensesChanged';

export const AnalyticsEvent: Record<AnalyticsEventKey, string> = {
  // core+common
  REFRESH_409: 'REFRESH_409',
  CONTINUE_409: 'CONTINUE_409',
  CLOSE_409: 'CLOSE_409',
  PageChange: 'PAGE_CHANGE',
  AppRecoveredFromKnownError: 'APP_RECOVERED_FROM_KNOWN_ERROR',
  AppKnownError: 'APP_ERROR_FROM_KNOWN_ERROR',
  AppErrorCaptured: 'APP_ERROR_CAPTURED',
  GdprConsent: 'GDPR_CONSENT',
  MethodologyOpened: 'METHODOLOGY_OPENED',
  EmailVerificationRequested:
    'EMAIL_VERIFY_RESEND_VERIFICATION_EMAIL_REQUESTED',
  EmailVerificationCompleted:
    'EMAIL_VERIFY_RESEND_VERIFICATION_EMAIL_COMPLETED',
  ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED:
    'ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED',
  MarketingBannerClicked: 'BANNER_CLICKED',
  LoginBtnClicked: 'LOGIN_SIGNIN_BTN_CLICKED',
  LoginWithPassword: 'LOGIN_WITH_PASS',
  LoginSuccess: 'LOGIN_SUCCESS',
  LoginError: 'LOGIN_ERROR',
  LoginFailure: 'LOGIN_SIGNIN_FAILURE',
  Logout: 'LOGOUT',
  LogoutFailure: 'LOGOUT_ATTEMPT_FAILURE',
  PasswordChangeRequested: 'PASSWORD_CHANGE_REQUESTED',
  PasswordChangeCompleted: 'PASSWORD_CHANGE_COMPLETED',
  PasswordChangeError: 'PASSWORD_CHANGE_ERROR',
  PasswordResetRequested: 'PASSWORD_RESET_REQUESTED',
  PasswordResetCompleted: 'PASSWORD_RESET_COMPLETED',
  PasswordResetError: 'PASSWORD_RESET_ERROR',

  SignupRequested: 'SIGNUP_REGISTRATION_REQUESTED',
  SignupComplete: 'SIGNUP_REGISTRATION_COMPLETE',
  SignupAutoLoginError: 'SIGNUP_AUTOLOGIN_ERROR',
  SignupFailed: 'SIGNUP_REGISTRATION_FAILED',
  SignupError: 'SIGNUP_REGISTRATION_ERROR',

  // polls
  PollSubmission: 'POLL_USER_SUBMIT_CHOICE',
  PollExpandToggle: 'POLL_EXPAND_COLLAPSE',

  // basis
  BasisDealsToggle: 'BASIS_DEALS_TOGGLE',
  BasisMorePageItemsClick: 'BASIS_MORE_PAGE_ITEM_LINK_CLICK',

  SettlementCriteriaChange: 'SETTLEMENT_CRITERIA_CHANGE',

  // ffa submission
  FFASubmissionJustificationChanged: 'FFA_SUBMISSION_JUSTIFICATION_CHANGED',
  FFASubmissionReviewBtnClicked: 'FFA_SUBMISSION_REVIEW_BTN_CLICKED',
  FFASubmissionError: 'FFA_SUBMISSION_ERROR',
  FFASubmissionBtnClicked: 'FFA_SUBMISSION_BTN_CLICKED',
  FFASubmissionFinished: 'FFA_SUBMISSION_FINISHED',
  FFASubmissionGoBack: 'FFA_SUBMISSION_BACK_NAVIGATION',

  FFASubmissionCancelOnReview: 'FFA_SUBMISSION_CANCELLED_ON_REVIEW',
  FFASubmissionChartPriceTypeChanged: 'FFA_SUBMISSION_CHART_DIMENSION_CHANGED',
  FFASubmissionChartToggled: 'FFA_SUBMISSION_CHART_TOGGLED',
  FFASubmissionImportPricesModalToggle:
    'FFA_SUBMISSION_TOGGLE_IMPORT_PRICES_MODAL',
  FFASubmissionImportPricesFromExcelApply:
    'FFA_SUBMISSION_IMPORT_PRICES_FROM_EXCEL_APPLY',

  // submission
  SubmissionJustificationChanged: 'SUBMISSION_JUSTIFICATION_CHANGED',
  SubmissionSegmentToggled: 'SUBMISSION_SEGMENT_TOGGLED',
  SubmissionReviewBtnClicked: 'SUBMISSION_REVIEW_BTN_CLICKED',
  SubmissionError: 'SUBMISSION_ERROR',
  SubmissionFinished: 'SUBMISSION_FINISHED',
  SubmissionBtnClicked: 'SUBMISSION_BTN_CLICKED',
  SubmissionGoBack: 'SUBMISSION_BACK_NAVIGATION',
  SubmissionExitModalToggled: 'SUBMISSION_EXIT_MODAL_TOGGLED',
  SubmissionCancelOnReview: 'SUBMISSION_CANCELLED_ON_REVIEW',
  SubmissionChartPriceTypeChanged: 'SUBMISSION_CHART_DIMENSION_CHANGED',
  SubmissionChartToggled: 'SUBMISSION_CHART_TOGGLED',
  SubmissionImportPricesModalToggle: 'SUBMISSION_TOGGLE_IMPORT_PRICES_MODAL',
  SubmissionImportPricesFromExcelApply:
    'SUBMISSION_IMPORT_PRICES_FROM_EXCEL_APPLY',
  SubmissionRTCalcRatesApplied: 'SUBMISSION_RT_CALC_RATES_APPLIED',

  // xls download
  ExcelDownloadRequest: 'DISCOVER_DOWNLOAD_REQUESTED',
  ExcelDownloadComplete: 'DISCOVER_DOWNLOAD_COMPLETE',
  ExcelDownloadFailed: 'DISCOVER_DOWNLOAD_FAILED',

  // discover page
  PriceChartPriceTypeComparisonChanged: 'PRICE_DISCOVERY_COMPARISON_CHANGED',
  ReleaseDateChanged: 'DISCOVER_RELEASE_DATE_CHANGED',
  ReleaseInfoOpened: 'DISCOVER_RELEASE_INFO_POPUP_OPENED',
  ActionBarButtonClicked: 'ACTION_BAR_BTN_CLICKED',
  SubmissionStatusRefresh: 'REFRESH_SUBMISSION_STATUS',
  UnitChanged: 'UNIT_CHANGED',
  FFAActionBarButtonClicked: 'FFA_ACTION_BAR_BTN_CLICKED',

  // price-evolution
  PriceEvolutionComparisonMonthChanged:
    'PRICE_EVOLUTION_COMPARISON_MONTH_CHANGED',
  PriceEvolutionFiltersContractChange:
    'PRICE_EVOLUTION_FILTERS_CONTRACT_CHANGED',
  PriceEvolutionFiltersContractTypeChange:
    'PRICE_EVOLUTION_FILTERS_CONTRACT_TYPE_CHANGED',
  PriceEvolutionFiltersUnitToggled: 'PRICE_EVOLUTION_FILTERS_UNIT_TOGGLED',
  PriceEvolutionFiltersDelMonthChange:
    'PRICE_EVOLUTION_FILTERS_DEL_MONTH_CHANGED',
  PriceEvolutionCustomToggled: 'PRICE_EVOLUTION_CUSTOM_TOGGLED',
  PriceEvolutionFiltersPercentToggled:
    'PRICE_EVOLUTION_FILTERS_PERCENT_TOGGLED',
  PriceEvolutionDurationChange: 'PRICE_EVOLUTION_DURATION_CHANGE',
  PriceEvolutionTerminalChange: 'PRICE_EVOLUTION_TERMINAL_CHANGE',
  PriceEvolutionSlotTypeChange: 'PRICE_EVOLUTION_SLOT_TYPE_CHANGE',

  // ffa-price-evolution
  FFAPriceEvolutionDurationChange: 'FFA_PRICE_EVOLUTION_DURATION_CHANGE',
  FFAPriceEvolutionFiltersContractChange:
    'FFA_PRICE_EVOLUTION_FILTERS_CONTRACT_CHANGED',
  FFAPriceEvolutionFiltersDelMonthChange:
    'FFA_PRICE_EVOLUTION_FILTERS_DEL_MONTH_CHANGED',

  // notes
  DiscoverNotesSaved: 'NOTES_SAVED_DISCOVER',
  DiscoverNotesLoaded: 'NOTES_LOADED_DISCOVER',
  NetbackNotesSaved: 'NOTES_SAVED_NETBACK_DISCOVER',
  NetbackNotesLoaded: 'NOTES_LOADED_NETBACK_DISCOVER',
  RoutesNotesSaved: 'NOTES_SAVED_ROUTES_DISCOVER',
  RoutesNotesLoaded: 'NOTES_LOADED_ROUTES_DISCOVER',
  FreightNotesSaved: 'NOTES_SAVED_PHYSICAL_DISCOVER',
  FreightNotesLoaded: 'NOTES_LOADED_PHYSICAL_DISCOVER',
  FFANotesSaved: 'NOTES_SAVED_FFA_DISCOVER',
  FFANotesLoaded: 'NOTES_LOADED_FFA_DISCOVER',
  FloatingStorageNotesSaved: 'NOTES_SAVED_FLOATING_STORAGE',
  FloatingStorageNotesLoaded: 'NOTES_LOADED_FLOATING_STORAGE',
  NetForwardsNotesSaved: 'NOTES_SAVED_NETFORWARDS',
  NetForwardsNotesLoaded: 'NOTES_LOADED_NETFORWARDS',
  NetbackArbNotesSaved: 'NOTES_SAVED_NETBACK_ARBS',
  NetbackArbNotesLoaded: 'NOTES_LOADED_NETBACK_ARBS',

  // ffa
  FFADiscoverNotesSaved: 'NOTES_SAVED_FFA_DISCOVER',
  FFADiscoverNotesLoaded: 'NOTES_LOADED_FFA_DISCOVER',
  FFADiscoverPriceTypeChanged: 'FFA_DISCOVER_PRICE_FILTER_DIMENSION_TOGGLED',

  // spread
  SpreadFiltersChange: 'SPREAD_FILTERS_CHANGED',
  SpreadFiltersUnitChange: 'SPREAD_FILTERS_UNIT_TOGGLED',

  // routes
  RoutesCriteriaUpdated: 'ROUTES_CRTIERIA_UPDATED',
  RoutesCriteriaCongestionDaysUpdated:
    'ROUTES_CRITERIA_CONGESTION_DAYS_UPDATED',

  // netbacks
  NetbacksCriteriaUpdated: 'NETBACKS_CRTIERIA_UPDATED',
  NetbacksCriteriaCongestionDaysUpdated:
    'NETBACKS_CRITERIA_CONGESTION_DAYS_UPDATED',

  //netforwards
  NetforwardsCriteriaCongestionDaysUpdated:
    'NETFORWARDS_CRITERIA_CONGESTION_DAYS_UPDATED',

  // ffa spread
  FFASpreadFiltersChange: 'FFA_SPREAD_FILTERS_CHANGED',

  // settlement
  SettlementMonthChange: 'SETTLEMENT_MONTH_CHANGE',
  SettlementContractChange: 'SETTLEMENT_CONTRACT_CHANGE',
  SettlementContractTypeChange: 'SETTLEMENT_CONTRACT_TYPE_CHANGE',
  SettlementMaxLossChange: 'SETTLEMENT_MAX_LOSS_CHANGE',
  SettlementMaxGainChange: 'SETTLEMENT_MAX_GAIN_CHANGE',

  // freight price evolution
  FreightPriceEvolutionSignalChange: 'FREIGHT_PRICE_EVOLUTION_SIGNAL_CHANGE',
  FreightPriceEvolutionDurationChange:
    'FREIGHT_PRICE_EVOLUTION_DURATION_CHANGE',
  FreightPriceEvolutionCompareFoChange:
    'FREIGHT_PRICE_EVOLUTION_COMPARE_FO_CHANGE',
  FreightPriceEvolutionCustomRangeChange:
    'PRICE_EVOLUTION_CUSTOM_RANGE_CHANGED',

  // freight discover signals
  FreightSpotSignalsToggled: 'DISCOVER_SPOT_SIGNALS_TOGGLED',

  // round trip calc
  RoundtripCalcSubmitBtnClick: 'RT_CALCULATOR_SUBMIT_BTN_CLICK',
  RoundtripCalcRemoveRate: 'RT_CALCULATOR_REMOVE_RATE',

  // trial
  StartTrialRequest: 'FREE_TRIAL_PAGE_START_TRIAL',
  TrialPageClosed: 'FREE_TRIAL_PAGE_CLOSED_SUCCESS',
  TrialPageClosureFailed: 'FREE_TRIAL_PAGE_CLOSED_ERROR',

  // more/documentations
  DocumentationLinkClicked: 'GOVERNANCE_AND_MORE_LINK_CLICK',

  // dashboard
  LaunchedViaDashboardPage: 'DASHBOARD_LAUNCH_PAGE',
  DashboardSignalsContractTypeChange: 'DASHBOARD_SIGNALS_CONTRACTTYPE_CHANGE',
  DashboardSignalsContractIdChange: 'DASHBOARD_SIGNALS_CONTRACTID_CHANGE',
  DashboardPDContractChange: 'DASHBOARD_PD_CONTRACT_SWITCH',
  DashboardPDMethodologyOpened: 'DASHBOARD_PD_METHODOLOGY_OPENED',
  DashboardUnitToggle: 'DASHBOARD_UNIT_TOGGLE',
  DashboardDerivateToggle: 'DASHBOARD_DERIVATIVE_TOGGLE',

  // basic-dashboard
  DashboardExplorePremium: 'BASIC_DASHBOARD_TOGGLE_EXPLORE_PREMIUM',
  DashboardPDFDownload: 'BASIC_DASHBOARD_CLICK_DOWNLOAD_PDF',
  DashboardDiscussPremium: 'BASIC_DASHBOARD_CLICK_DISCUSS_PREMIUM',
  DashboardViewFullDetails: 'BASIC_DASHBOARD_CLICK_VIEW_FULL_DETAILS,',

  // banner
  BannerClick: 'BANNER_CLICK',

  // access/terminal-map
  AccessWindow: 'ACCESS_WINDOW',
  MapPinTerminal: 'MAP_PIN_TERMINAL',
  MapUnpinTerminal: 'MAP_UNPIN_TERMINAL',
  MapHoverGashub: 'MAP_HOVER_GASHUB',
  MapTerminalComparison: 'MAP_TERMINAL_COMPARISON',
  MapHoverTerminal: 'MAP_HOVER_TERMINAL',
  MapUnitChange: 'MAP_UNIT_CHANGE',
  MapFeedbackModal: 'MAP_FEEDBACK_MODAL',

  // access/table-view
  ComparisonTableCsvModal: 'ComparisonTableCsvModal',

  // announcement
  AnnouncementShown: 'ANNOUNCEMENT_SHOWN',
  AnnouncementAcknowledged: 'ANNOUNCEMENT_ACKNOWLEDGED',
  AnnouncementContactUs: 'ANNOUNCEMENT_CONTACT_US',

  // recent update
  RecentUpdateClicked: 'RECENT_UPDATE_CLICKED',

  // netbacks arb
  NetbacksArbCriteriaUpdated: 'NETBACKS_ARB_CRITERIA_UPDATED',
  NetbacksArbCriteriaCongestionDaysUpdated:
    'NETBACKS_ARB_CRITERIA_CONGESTION_DAYS_UPDATED',

  // page prompts
  PagePromptsLinkClicked: 'PAGE_PROMPTS_LINK_CLICKED',

  //Global Calculator
  //Routes
  GlobalCalculatorLoadDateChanged: 'GLOBAL_CALCULATOR_ROUTES_LOAD_DATE_CHANGED',
  GlobalCalculatorLoadPortChanged: 'GLOBAL_CALCULATOR_LOAD_PORT_CHANGED',
  GlobalCalculatorDischargePortChanged:
    'GLOBAL_CALCULATOR_DISCHARGE_PORT_CHANGED',
  GlobalCalculatorViaPointChanged: 'GLOBAL_CALCULATOR_VIA_POINT_CHANGED',
  //Routes secondary
  GlobalCalculatorLoadPortDaysChanged:
    'GLOBAL_CALCULATOR_LOAD_PORT_DAYS_CHANGED',
  GlobalCalculatorDischargePortDaysChanged:
    'GLOBAL_CALCULATOR_DISCHARGE_PORT_DAYS_CHANGED',
  GlobalCalculatorFlexDaysChanged: 'GLOBAL_CALCULATOR_FLEX_DAYS_CHANGED',
  GlobalCalculatorCanalTransitLadenDaysChanged:
    'GLOBAL_CALCULATOR_CANAL_TRANSIT_LADEN_DAYS_CHANGED',
  GlobalCalculatorCanalTransitBallastDaysChanged:
    'GLOBAL_CALCULATOR_CANAL_TRANSIT_BALLAST_DAYS_CHANGED',
  GlobalCalculatorCongestionLadenDaysChanged:
    'GLOBAL_CALCULATOR_CONGESTION_DAYS_CHANGED',
  GlobalCalculatorCongestionBallastDaysChanged:
    'GLOBAL_CALCULATOR_CONGESTION_DAYS_CHANGED',
  GlobalCalculatorSeaMarginChanged: 'GLOBAL_CALCULATOR_SEA_MARGIN_CHANGED',
  //Vessel
  GlobalCalculatorVesselSizeChanged: 'GLOBAL_CALCULATOR_VESSEL_SIZE_CHANGED',
  GlobalCalculatorVesselHeelPurchasedChanged:
    'GLOBAL_CALCULATOR_VESSEL_HEEL_PURCHASED_CHANGED',
  GlobalCalculatorVesselCargoLoadedChanged:
    'GLOBAL_CALCULATOR_VESSEL_CARGO_LOADED_CHANGED',
  GlobalCalculatorVesselHeelSoldChanged:
    'GLOBAL_CALCULATOR_VESSEL_HEEL_SOLD_CHANGED',
  GlobalCalculatorVesselEngineTechChanged:
    'GLOBAL_CALCULATOR_VESSEL_ENGINE_TECH_CHANGED',
  //fuel consumption
  GlobalCalculatorEffectiveLadenBoilOffChanged:
    'GLOBAL_CALCULATOR_EFFECTIVE_LADEN_BOIL_OFF_CHANGED',
  GlobalCalculatorEffectiveBallastBoilOffChanged:
    'GLOBAL_CALCULATOR_EFFECTIVE_BALLAST_BOIL_OFF_CHANGED',
  GlobalCalculatorLadenSpeedChanged: 'GLOBAL_CALCULATOR_LADEN_SPEED_CHANGED',
  GlobalCalculatorLadenMgoChanged: 'GLOBAL_CALCULATOR_LADEN_MGO_CHANGED',
  GlobalCalculatorLadenFuelOilChanged:
    'GLOBAL_CALCULATOR_LADEN_FUEL_OIL_CHANGED',
  GlobalCalculatorBallastSpeedChanged:
    'GLOBAL_CALCULATOR_BALLAST_SPEED_CHANGED',
  GlobalCalculatorBallastMgoChanged: 'GLOBAL_CALCULATOR_BALLAST_MGO_CHANGED',
  GlobalCalculatorBallastFuelOilChanged:
    'GLOBAL_CALCULATOR_BALLAST_FUEL_OIL_CHANGED',
  GlobalCalculatorPortEffectiveBoilOffChanged:
    'GLOBAL_CALCULATOR_PORT_EFFECTIVE_BOIL_OFF_CHANGED',
  GlobalCalculatorPortMgoChanged: 'GLOBAL_CALCULATOR_PORT_MGO_CHANGED',
  GlobalCalculatorPortFuelOilChanged: 'GLOBAL_CALCULATOR_PORT_FUEL_OIL_CHANGED',
  //Commodity
  GlobalCalculatorLngPriceChanged: 'GLOBAL_CALCULATOR_LNG_PRICE_CHANGED',
  GlobalCalculatorHeelPurchasedChanged:
    'GLOBAL_CALCULATOR_HEEL_PURCHASED_CHANGED',
  GlobalCalculatorHeelSoldChanged: 'GLOBAL_CALCULATOR_HEEL_SOLD_CHANGED',
  GlobalCalculatorMgoPriceChanged: 'GLOBAL_CALCULATOR_MGO_PRICE_CHANGED',
  GlobalCalculatorFuelOilPriceChanged:
    'GLOBAL_CALCULATOR_FUEL_OIL_PRICE_CHANGED',
  GlobalCalculatorIncludeCarbonCostChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST_CHANGED',
  GlobalCalculatorIncludeCarbonCostForLoadPortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST_FOR_LOAD_PORT_CHANGED',
  GlobalCalculatorIncludeCarbonCostForLadenTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST_FOR_LADEN_TRANSIT_CHANGED',
  GlobalCalculatorIncludeCarbonCostForDischargePortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST_FOR_DISCHARGE_PORT_CHANGED',
  GlobalCalculatorIncludeCarbonCostForBallastTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST_FOR_BALLAST_TRANSIT_CHANGED',
  GlobalCalculatorIncludeCarbonCostForPositioningTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_CARBON_COST',
  GlobalCalculatorCarbonLoadPortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_CARBON_LOAD_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorCarbonLoadPortImplementationChanged:
    'GLOBAL_CALCULATOR_CARBON_LOAD_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorCarbonLadenTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_CARBON_LADEN_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorCarbonLadenTransitImplementationChanged:
    'GLOBAL_CALCULATOR_CARBON_LADEN_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorCarbonDischargePortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_CARBON_DISCHARGE_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorCarbonDischargePortImplementationChanged:
    'GLOBAL_CALCULATOR_CARBON_DISCHARGE_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorCarbonBallastTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_CARBON_BALLAST_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorCarbonBallastTransitImplementationChanged:
    'GLOBAL_CALCULATOR_CARBON_BALLAST_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorCarbonPositioningTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_CARBON_POSITIONING_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorCarbonPositioningTransitImplementationChanged:
    'GLOBAL_CALCUATOR_CARBON_POSITIONING_TRANSIT_IMPLEMENTATION_CHANGED',

  GlobalCalculatorIncludeMethaneCostChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_CHANGED',
  GlobalCalculatorIncludeMethaneCostForLoadPortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_FOR_LOAD_PORT_CHANGED',
  GlobalCalculatorIncludeMethaneCostForLadenTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_FOR_LADEN_TRANSIT_CHANGED',
  GlobalCalculatorIncludeMethaneCostForDischargePortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_FOR_DISCHARGE_PORT_CHANGED',
  GlobalCalculatorIncludeMethaneCostForBallastTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_FOR_BALLAST_TRANSIT_CHANGED',
  GlobalCalculatorIncludeMethaneCostForPositioningTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST',
  GlobalCalculatorMethaneLoadPortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_METHANE_LOAD_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorMethaneLoadPortImplementationChanged:
    'GLOBAL_CALCULATOR_METHANE_LOAD_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorMethaneLadenTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_METHANE_LADEN_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorMethaneLadenTransitImplementationChanged:
    'GLOBAL_CALCULATOR_METHANE_LADEN_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorMethaneDischargePortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_METHANE_DISCHARGE_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorMethaneDischargePortImplementationChanged:
    'GLOBAL_CALCULATOR_METHANE_DISCHARGE_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorMethaneBallastTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_METHANE_BALLAST_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorMethaneBallastTransitImplementationChanged:
    'GLOBAL_CALCULATOR_METHANE_BALLAST_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorMethanePositioningTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_METHANE_POSITIONING_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorMethanePositioningTransitImplementationChanged:
    'GLOBAL_CALCUATOR_METHANE_POSITIONING_TRANSIT_IMPLEMENTATION_CHANGED',

  GlobalCalculatorIncludeN2OCostChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_CHANGED',
  GlobalCalculatorIncludeN2OCostForLoadPortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_METHANE_COST_FOR_LOAD_PORT_CHANGED',
  GlobalCalculatorIncludeN2OCostForLadenTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_N2O_COST_FOR_LADEN_TRANSIT_CHANGED',
  GlobalCalculatorIncludeN2OCostForDischargePortChanged:
    'GLOBAL_CALCULATOR_INCLUDE_N2O_COST_FOR_DISCHARGE_PORT_CHANGED',
  GlobalCalculatorIncludeN2OCostForBallastTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_N2O_COST_FOR_BALLAST_TRANSIT_CHANGED',
  GlobalCalculatorIncludeN2OCostForPositioningTransitChanged:
    'GLOBAL_CALCULATOR_INCLUDE_N2O_COST',
  GlobalCalculatorN2OLoadPortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_N2O_LOAD_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorN2OLoadPortImplementationChanged:
    'GLOBAL_CALCULATOR_N2O_LOAD_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorN2OLadenTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_N2O_LADEN_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorN2OLadenTransitImplementationChanged:
    'GLOBAL_CALCULATOR_N2O_LADEN_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorN2ODischargePortVoyageFactorChanged:
    'GLOBAL_CALCULATOR_N2O_DISCHARGE_PORT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorN2ODischargePortImplementationChanged:
    'GLOBAL_CALCULATOR_N2O_DISCHARGE_PORT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorN2OBallastTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_N2O_BALLAST_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorN2OBallastTransitImplementationChanged:
    'GLOBAL_CALCULATOR_N2O_BALLAST_TRANSIT_IMPLEMENTATION_CHANGED',
  GlobalCalculatorN2OPositioningTransitVoyageFactorChanged:
    'GLOBAL_CALCULATOR_N2O_POSITIONING_TRANSIT_VOYAGE_FACTOR_CHANGED',
  GlobalCalculatorN2OPositioningTransitImplementationChanged:
    'GLOBAL_CALCUATOR_N2O_POSITIONING_TRANSIT_IMPLEMENTATION_CHANGED',

  GlobalCalculatorCarbonPriceChanged: 'GLOBAL_CALCULATOR_CARBON_PRICE_CHANGED',
  //Hire Cost
  GlobalCalculatorHireRateChanged: 'GLOBAL_CALCULATOR_HIRE_RATE_CHANGED',
  GlobalCalculatorCanalCostChanged: 'GLOBAL_CALCULATOR_CANAL_COST_CHANGED',
  //BB
  GlobalCalculatorBallastBonusFormatChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_FORMAT_CHANGED',
  GlobalCalculatorBallastBonusToChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_TO_CHANGED',
  GlobalCalculatorBallastBonusHirePercentageChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_HIRE_PERCENTAGE_CHANGED',
  GlobalCalculatorBallastBonusFuelPercentageChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_FUEL_PERCENTAGE_CHANGED',
  GlobalCalculatorBallastBonusCanalCostChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_CANAL_COST_CHANGED',
  GlobalCalculatorBallastBonusLumpsumChanged:
    'GLOBAL_CALCULATOR_BALLAST_BONUS_LUMPSUM_CHANGED',
  //Positioning
  GlobalCalculatorPositioningFormatChanged:
    'GLOBAL_CALCULATOR_POSITIONING_FORMAT_CHANGED',
  GlobalCalculatorPositioningFromChanged:
    'GLOBAL_CALCULATOR_POSITIONING_FROM_CHANGED',
  GlobalCalculatorPositioningHirePercentageChanged:
    'GLOBAL_CALCULATOR_POSITIONING_HIRE_PERCENTAGE_CHANGED',
  GlobalCalculatorPositioningFuelPercentageChanged:
    'GLOBAL_CALCULATOR_POSITIONING_FUEL_PERCENTAGE_CHANGED',
  GlobalCalculatorPositioningCanalCostChanged:
    'GLOBAL_CALCULATOR_POSITIONING_CANAL_COST_CHANGED',
  GlobalCalculatorPositioningLumpsumChanged:
    'GLOBAL_CALCULATOR_POSITIONING_LUMPSUM_CHANGED',
  //Additional costs
  GlobalCalculatorLoadPortCostChanged:
    'GLOBAL_CALCULATOR_LOAD_PORT_COST_CHANGED',
  GlobalCalculatorDischargePortCostChanged:
    'GLOBAL_CALCULATOR_DISCHARGE_PORT_COST_CHANGED',
  GlobalCalculatorInsuranceCostChanged:
    'GLOBAL_CALCULATOR_INSURANCE_COST_CHANGED',
  GlobalCalculatorSecurityCostChanged:
    'GLOBAL_CALCULATOR_SECURITY_COST_CHANGED',
  GlobalCalculatorBrokerFeeFormatChanged:
    'GLOBAL_CALCULATOR_BROKER_FEE_FORMAT_CHANGED',
  GlobalCalculatorBrokerFeeChanged: 'GLOBAL_CALCULATOR_BROKER_FEE_CHANGED',
  GlobalCalculatorOtherExpensesChanged:
    'GLOBAL_CALCULATOR_OTHER_EXPENSES_CHANGED',
};
